import urijs from "urijs";

export default class ParameterWatcher {
  #parameterName = null;
  #currentValue = null;

  constructor(parameterName) {
    this.#parameterName = parameterName;
    this.#currentValue = this.getCurrentValue();
  }

  getParameterName() {
    return this.#parameterName;
  }

  getCurrentValue() {
    const parsedQuery = urijs(window.location.href).search(true);
    return parsedQuery[this.#parameterName] || null;
  }

  updateCurrentValue(newValue) {
    // value did not change --> nothing to do
    if(this.#currentValue === newValue) {
      return;
    }

    const parsedUri = urijs(window.location.href);
    if(newValue) {
      parsedUri.setSearch(this.#parameterName, newValue);
    } else {
      parsedUri.removeSearch(this.#parameterName);
    }
    window.location.href = parsedUri.toString();
  }
}
