import jQuery from "jquery";

export default function(buttonSelector) {
  const button = jQuery(buttonSelector);
  button.hide();
  return () => {
    button.show();
    jQuery('.recaptcha-error').remove();
  }
}
