// see base.js for all variables

import baseThemeData from "apps/shared/cookie-consent/base";

export default Object.freeze({
  ...baseThemeData,
  siteName: 'Surgeon Talk',
  colorAccent: '#FFFFFF',
  colorHighlight: '#C10B25',
})
