import jQuery from "jquery";

import allThemes from "apps/shared/cookie-consent/all";

export default function (themeName = null) {

  const themeData = allThemes[themeName] || allThemes["base"];

  jQuery(document).ready(() => {
    jQuery("body").mycookielayer({
      brand: themeData.siteName,
      analyse: true,
      link: {
        privacy: {
          de: `<a href="${themeData.privacyLink}">Datenschutzerklärung</a>`,
          en: '<a href="/en/privacy-policy/#hmcl">data protection</a>',
        },
      },
      css: {
        classes: {
          bar_wrapper: "container",
        },
        bar: {
          backgroundColor: themeData.colorAccent,
        },
        aufklapper: {
          fontSize: "37px",
          lineHeight: "0.9",
          textAlign: "right",
        },
      },
      css_mobile: {
        modal: {
          padding: "0px",
        },
      },
    });

    jQuery(".my_cookielayer_button_save").css({
      background: "white",
      border: `1px solid ${themeData.colorHighlight}`,
      "border-radius": "0",
      color: themeData.colorHighlight,
      width: "240px",
      height: "54px",
      padding: "0 20px",
      display: "inline-block",
      "line-height": "4",
    });

    jQuery(".cookie-text").css({
      "margin-right": "33px",
      "text-align": "left",
      "line-height": "2rem",
      "font-size": "14px",
    });

    jQuery(".my_cookielayer_intro").css({
      "margin-right": "30px",
      "text-align": "left",
    });

    jQuery(".my_cookielayer_button_accept_all").css({
      background: themeData.colorHighlight,
      border: `1px solid ${themeData.colorHighlight}`,
      "border-radius": "0",
      color: "white",
      width: "240px",
      height: "54px",
      padding: "0 20px",
      display: "inline-block",
      "line-height": "4",
    });

    jQuery(".my_cookielayer_button_save").hover(
      function () {
        jQuery(this).css({
          background: themeData.colorHighlight,
          color: "white",
          border: `1px solid ${themeData.colorHighlight}`,
        });
      },
      function () {
        jQuery(this).css({
          background: "white",
          border: `1px solid ${themeData.colorHighlight}`,
          color: themeData.colorHighlight,
        });
      }
    );

    jQuery(".my_cookielayer_button_accept_all").hover(
      function () {
        jQuery(this).css({
          color: "black",
        });
      },
      function () {
        jQuery(this).css({
          color: "white",
        });
      }
    );
    jQuery(".my_cookielayer_settings_section").hover(
      function () {
        jQuery(this).find("span").css("font-weight", "600", "!important");
      },
      function () {
        jQuery(this).find("span").css("font-weight", "100");
      }
    );
  });
}
