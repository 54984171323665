// Helper function to import a subdirectory into
// bdc_eakademie."key_name" or directly into key_name (if you give a hash)
// It always imports the default module
// the key can either be the filename only (namespaceSubfolders = null)
// or the whole path joined together with the content of namespaceSubfolders
export default function (
  keyOrStore,
  webpackContext,
  namespaceSubfolders = null
) {
  let result;
  if (typeof keyOrStore === "string") {
    window.bdc_eakademie = window.bdc_eakademie || {};
    window.bdc_eakademie[keyOrStore] = window.bdc_eakademie[keyOrStore] || {};
    result = window.bdc_eakademie[keyOrStore];
  } else {
    result = keyOrStore;
  }

  webpackContext.keys().forEach((key) => {
    let slicer = -1; // we only use the file name as key
    if(namespaceSubfolders) {
      slicer = 1; // we use the whole path as key
    }
    const moduleName = key.split("/").slice(slicer).join(namespaceSubfolders || "").split(".")[0];
    try {
      result[moduleName] = webpackContext(key).default;
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error(`Could not load module '${key}'.`, e);
    }
  });
}
