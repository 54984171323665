import jQuery from "jquery";

export default function(errorAreaSelector) {
  const errorArea = jQuery(errorAreaSelector);
  return () => {
    const error = jQuery('<div>');
    error.addClass('recaptcha-error');
    error.text("Ein Fehler beim Lösen des Captchas, versuchen Sie es bitte erneut");
    errorArea.append(error);
  }
}
