import jQuery from "jquery";

export default function(id, title, content, confirmButton, cancelButton) {
  return jQuery.parseHTML(`
      <div class="modal" id="${id}" aria-hidden="true">
        <div class="modal__overlay" tabindex="-1" data-micromodal-close>
          <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="${id}-title">
            <header class="modal__header">
              <h2 class="modal__title" id="${id}-title">
                ${title}
              </h2>
              <button class="modal__close" aria-label="Dialog schließen" data-micromodal-close></button>
            </header>
            <main class="modal__content" id="${id}-content">
              ${content}
            </main>
            <footer class="modal__footer">
              <button class="modal__btn" data-micromodal-close aria-label="Diesen Dialog schließen">${cancelButton}</button>
              <button class="modal__btn modal__btn-primary">${confirmButton}</button>
            </footer>
          </div>
        </div>
      </div>
    `.trim())[0]
}
