import jQuery from "jquery";

export default function () {
  (function () {
    (function ($, window, document) {
      var Plugin, defaults, pluginName;

      pluginName = "mycookielayer";
      defaults = {
        lang: "de",

        analyse: false,
        marketing: false,

        analyseCookieName: "analyse-disabled",
        marketingCookieName: "marketing-disabled",

        brand: "Der Betreiber der Webseite",
        type: "layer",
        text: {
          headline: {
            de: "Cookie Hinweis und Einstellungen",
            en: "Cookie notice and settings",
          },
          bar: {
            de: "Diese Webseite verwendet Cookies. Mit dem Besuch der Seite erklären Sie sich damit einverstanden.",
            en: "This site uses cookies. By using the website you agree to the use of cookies.",
          },
          bar_more: {
            de: "Mehr Informationen",
            en: "More information",
          },
          bar_ok: {
            de: "Cookies akzeptieren",
            en: "Accept Cookies",
          },
          intro: {
            de: "[brand] benutzt Cookies um Ihnen das Online-Angebot bereitstellen zu können. In diese Kategorie fallen z.B. Session-Cookies, die uns helfen, Nutzer zu identifizieren. Zudem werden Cookies eingesetzt, mit denen das Benutzerverhalten auf der Seite verfolgt wird. Sie können selbst entscheiden, welche Kategorien Sie zulassen möchten. Weitere Informationen finden Sie in unseren Datenschutzhinweisen.",
            en: "[brand] uses cookies to provide you with the online offer. This category includes e.g. session cookies that help us identify users. Cookies are also used to track user behavior on the site. You can decide which categories you want to allow. You can find further information in our privacy notice.",
          },
          dataprivacy_link: {
            de: "[privacy_link]",
            en: "[privacy_link]",
          },
          functional: {
            de: '<span>Session Cookies (notwendig)</span><a>&#65291;</a><p class="cookie-text non-active-text">Diese Cookies sind kleine Informationseinheiten, die für den grundlegenden Betrieb der Webseite erforderlich sind und bleiben daher immer aktiviert. Ein Session-Cookie erzeugt eine zufällige ID und wird im Arbeitsspeicher des Nutzers abgelegt. Er hilft [brand] bei der Nutzung der Seite innerhalb einer Sitzung oder über die Sitzung hinaus bis zum nächsten Besuch, falls Sie dem zugestimmt haben.</p>',
            en: '<span>Session cookies (necessary)</span><a>&#65291;</a><p class="cookie-text non-active-text">These cookies are small pieces of information that are necessary for the basic operation of the website and therefore always remain activated. A session cookie generates a random ID and is stored in the user\'s working memory. It helps [brand] to use the site within a session or beyond the session until the next visit, if you have consented to this.</p>',
          },
          analyse: {
            de: '<span>Analyse Cookies</span><a>&#65291;</a><p class="cookie-text non-active-text">Wir nutzen auf unserer Website [brand] die Software „Matomo“ (www.matomo.org), einen Dienst des Anbieters InnoCraft Ltd., 150 Willis St, 6011 Wellington, Neuseeland. Die Software setzt einen Cookie auf ihrem Computer, mit dem Ihr Browser wiedererkannt werden kann. Die so erhobenen Informationen werden ausschließlich und anonymisiert auf unserem Server gespeichert. Eine Weitergabe an Dritte erfolgt nicht.</p>',
            en: '<span>Analytics Cookies</span><a>&#65291;</a><p class="cookie-text non-active-text">On our website [brand], we use the “Matomo” software (www.matomo.org), a service provided by InnoCraft Ltd., 150 Willis St, 6011 Wellington, New Zealand. The software places a cookie on your computer with which your browser can be recognized. The information collected in this way is stored exclusively and anonymously on our server. It will not be passed on to third parties.</p>',
          },
          marketing: {
            de: '<span>Marketing & Social Media</span><a>&#65291;</a><p class="cookie-text non-active-text">Wir nutzen auf unserer Website [brand] die Software „Matomo“ (www.matomo.org), einen Dienst des Anbieters InnoCraft Ltd., 150 Willis St, 6011 Wellington, Neuseeland. Die Software setzt einen Cookie auf ihrem Computer, mit dem Ihr Browser wiedererkannt werden kann. Die so erhobenen Informationen werden ausschließlich und anonymisiert auf unserem Server gespeichert. Eine Weitergabe an Dritte erfolgt nicht.</p>',
            en: '<span>Marketing & Social Media</span><a>&#65291;</a><p class="cookie-text non-active-text">On our website [brand], we use the “Matomo” software (www.matomo.org), a service provided by InnoCraft Ltd., 150 Willis St, 6011 Wellington, New Zealand. The software places a cookie on your computer with which your browser can be recognized. The information collected in this way is stored exclusively and anonymously on our server. It will not be passed on to third parties.</p>',
          },
          button_accept_all: {
            de: "Alle Cookies akzeptieren",
            en: "Accept all cookies",
          },
          button_save: {
            de: "Auswahl bestätigen",
            en: "Apply selection",
          },
          footer: {
            de: "Die Datenschutzeinstellungen können jederzeit geändert werden. Weitere Informationen befinden sich auf der Seite [privacy_link]",
            en: "You can change the data privacy settings at any time. Please visit our [privacy_link] for further information.",
          },
        },
        link: {
          privacy: {
            de: '<a href="/datenschutzerklaerung">Datenschutzerklärung</a>',
            en: '<a href="/privacy">privacy notice</a>',
          },
        },
        css: {
          classes: {
            button_save: "button btn btn-light",
            button_accept_all: "button btn btn-primary",
            bar_wrapper: "container",
            bar_ok: "button",
          },
          bar: {
            display: "none",
            position: "fixed",
            top: "auto",
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "black",
            color: "white",
            lineHeight: 1.5,
            padding: "10px 30px",
            zIndex: 3000,
          },
          bar_inner: {
            maxWidth: "1920px",
            margin: "0 auto",
          },
          bar_more: {
            color: "white",
            textDecoration: "underline",
          },
          bar_ok: {
            float: "right",
            display: "block",
            backgroundColor: "white",
            color: "black",
            padding: "1px 10px",
            margin: "-1px 0 -1px 20px",
          },
          overlay: {
            display: "none",
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: "rgba(0,0,0,0.8)",
            zIndex: 3999,
          },
          modal: {
            display: "none",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            color: "black",
            lineHeight: 1.4,
            zIndex: 4000,
            maxWidth: "680px",
            width: "95%",
            boxSizing: "border-box",
          },
          header: {
            padding: "40px 40px 20px",
            marginBottom: "30px",
            background: "#EDEEEF",
          },
          headline: {
            marginBottom: "20px",
            textAlign: "center",
            fontWeight: "600",
            textAlign: "left",
          },
          intro: {
            marginBottom: "20px",
          },
          privacylink: {
            color: "#808080",
            textDecoration: "underline",
            fontSize: "13px",
          },
          buttons: {
            paddingBottom: "30px",
            textAlign: "center",
            margin: "30px 0 20px 0",
          },
          button: {
            paddingLeft: 0,
            paddingRight: 0,
            width: "240px",
            margin: "7px",
            letterSpacing: 0,
            textTransform: "none",
            fontWeight: "normal",
            padding: "7px 20px",
          },
          settings_section: {
            padding: "0 40px 20px",
            position: "relative",
          },
          settings_headline: {
            display: "block",
            position: "relative",
            margin: "0 0 8px 50px",
            lineHeight: "30px",
            cursor: "pointer",
            zIndex: "1",
          },
          settings_text: {
            marginLeft: "50px",
            display: "none",
          },
          input: {
            position: "absolute",
            margin: "0",
            width: "30px",
            height: "30px",
          },
          aufklapper: {
            position: "absolute",
            height: "30px",
            top: "0",
            right: "40px",
            width: "50px",
            fontSize: "1.5em",
          },
        },
        css_mobile: {
          modal: {
            position: "relative",
            transform: "none",
            top: "2.5%",
            left: "2.5%",
            padding: "20px",
          },
          buttons: {
            margin: "20px 0 10px 0",
            paddingBottom: "20px",
          },
        },
        callback: {
          init: function () {},
          update: function () {},
        },
      };
      Plugin = (function () {
        function Plugin(element, options) {
          this.element = element;
          this.options = $.extend(true, {}, defaults, options);
          this._defaults = defaults;
          this._name = pluginName;
          this.init();
        }

        return Plugin;
      })();

      (Plugin.prototype.init = function () {
        var $element = $(this.element);
        var _this = this;
        this.data = $.data(this);

        var $analyseDisabledCookie = this.options.analyseCookieName;
        var $marketingDisabledCookie = this.options.marketingCookieName;

        var $cookie_layer = $('<div class="my_cookielayer"></div>');
        $cookie_layer.css(this.options.css.modal);

        // Header Bereich
        var $cookie_header = $('<div class="my_cookielayer_header"></div>');
        $cookie_header.css(this.options.css.header);
        $cookie_layer.append($cookie_header);

        var $headline = $(
          '<h2 class="my_cookielayer_headline">' +
            this.options.text.headline[this.options.lang] +
            "</h2>"
        );
        $headline.css(this.options.css.headline);
        $cookie_header.append($headline);

        var $intro = $(
          '<div class="my_cookielayer_intro">' +
            this.options.text.intro[this.options.lang].replace(
              "[brand]",
              this.options.brand
            ) +
            "</div>"
        );
        $intro.css(this.options.css.intro);
        $cookie_header.append($intro);

        var $datenschutzlink = $(
          '<div class="my_cookielayer_privacylink">' +
            this.options.text.dataprivacy_link[this.options.lang].replace(
              "[privacy_link]",
              this.options.link.privacy[this.options.lang]
            ) +
            "</div>"
        );
        $($datenschutzlink).children().css(this.options.css.privacylink);
        $cookie_header.append($datenschutzlink);

        // Settings Bereich
        const $settings = $('<div class="my_cookielayer_settings"></div>');
        $cookie_layer.append($settings);

        // Notwendige Cookies
        let $settings_section = $(
          '<div class="my_cookielayer_settings_section"><input type="checkbox" name="functional" value="1" disabled="disabled" checked="checked" />' +
            this.options.text.functional[this.options.lang].replace(
              "[brand]",
              this.options.brand
            ) +
            "</div>"
        );
        $settings_section.css(this.options.css.settings_section);
        $settings_section.find("span").css(this.options.css.settings_headline);
        $settings_section.find("a").css(this.options.css.aufklapper);
        $settings_section.find("p").css(this.options.css.settings_text);
        $settings.append($settings_section);

        // Analyse Cookies
        if (this.options.analyse) {
          $settings_section = $(
            '<div class="my_cookielayer_settings_section"><input type="checkbox" name="analyse" id="my_cookielayer_input_analyse" value="1" />' +
              this.options.text.analyse[this.options.lang].replace(
                "[brand]",
                this.options.brand
              ) +
              "</div>"
          );
          $settings_section.css(this.options.css.settings_section);
          $settings_section
            .find("span")
            .css(this.options.css.settings_headline);
          $settings_section.find("a").css(this.options.css.aufklapper);
          $settings_section.find("p").css(this.options.css.settings_text);
          $settings.append($settings_section);
        }

        if (this.options.marketing) {
          $settings_section = $(
            '<div class="my_cookielayer_settings_section"><input type="checkbox" name="marketing" id="my_cookielayer_input_marketing" value="1" />' +
              this.options.text.marketing[this.options.lang].replace(
                "[brand]",
                this.options.brand
              ) +
              "</div>"
          );
          $settings_section.css(this.options.css.settings_section);
          $settings_section
            .find("span")
            .css(this.options.css.settings_headline);
          $settings_section.find("a").css(this.options.css.aufklapper);
          $settings_section.find("p").css(this.options.css.settings_text);
          $settings.append($settings_section);
        }

        $settings.find("input").css(this.options.css.input);

        // Buttons
        var $buttons = $('<div class="my_cookielayer_buttons"></div>');
        $buttons.css(this.options.css.buttons);
        $settings.append($buttons);

        var $button_save = $(
          '<a class="' +
            this.options.css.classes.button_save +
            '  my_cookielayer_button_save">' +
            this.options.text.button_save[this.options.lang] +
            "</a>"
        );
        $button_save.css(this.options.css.button);
        $buttons.append($button_save);

        var $button_accept_all = $(
          '<a class="' +
            this.options.css.classes.button_accept_all +
            ' my_cookielayer_button_accept_all">' +
            this.options.text.button_accept_all[this.options.lang] +
            "</a>"
        );
        $button_accept_all.css(this.options.css.button);
        $buttons.append($button_accept_all);

        $($element).prepend($cookie_layer);

        var $cookie_layer_overlay = $(
          '<div class="my_cookielayer_overlay"></div>'
        );
        $cookie_layer_overlay.css(this.options.css.overlay);

        $($element).prepend($cookie_layer_overlay);

        if (
          _this.getCookie($analyseDisabledCookie) == "" &&
          _this.getCookie($marketingDisabledCookie) == ""
        ) {
          if (this.options.type == "layer") {
            if (window.location.hash != "#hmcl") {
              _this.showCookieLayer();
            }
          }

          if (this.options.type == "bar") {
            _this.showCookieBar();
          }

          $("#my_cookielayer_input_analyse").prop("checked", false);
          $("#my_cookielayer_input_marketing").prop("checked", false);
        } else {
          if (_this.getCookie($analyseDisabledCookie) == "true") {
            $("#my_cookielayer_input_analyse").prop("checked", false);
          } else {
            $("#my_cookielayer_input_analyse").prop("checked", true);
          }

          if (_this.getCookie($marketingDisabledCookie) == "true") {
            $("#my_cookielayer_input_marketing").prop("checked", false);
          } else {
            $("#my_cookielayer_input_marketing").prop("checked", true);
          }
        }

        $button_accept_all.click(function () {
          $("#my_cookielayer_input_analyse").prop("checked", true);
          $("#my_cookielayer_input_marketing").prop("checked", true);

          if (_this.options.analyse) {
            _this.setCookie($analyseDisabledCookie, "false", 200);
          }
          if (_this.options.marketing) {
            _this.setCookie($marketingDisabledCookie, "false", 200);
          }

          setTimeout(function () {
            _this.hideCookieLayer();
            window.location.reload();
          }, 500);
        });

        $button_save.click(function () {
          var $reload = false;
          if ($("#my_cookielayer_input_analyse").prop("checked") == true) {
            if (_this.options.analyse) {
              _this.setCookie($analyseDisabledCookie, "false", 200);
            }
          } else {
            if (_this.options.analyse) {
              _this.setCookie($analyseDisabledCookie, "true", 200);
            }
          }

          if ($("#my_cookielayer_input_marketing").prop("checked") == true) {
            if (_this.options.marketing) {
              _this.setCookie($marketingDisabledCookie, "false", 200);
            }
          } else {
            if (_this.options.marketing) {
              _this.setCookie($marketingDisabledCookie, "true", 200);
            }
          }

          setTimeout(function () {
            _this.hideCookieLayer();
            window.location.reload();
          }, 500);
        });

        $(".open_my_cookielayer").click(function () {
          _this.showCookieLayer();
        });

        $(".open_my_cookielayer_settings").click(function () {
          $(".my_cookielayer_intro").hide();
          $(".my_cookielayer_settings").show();

          _this.showCookieLayer();
        });

        $(".my_cookielayer_settings_section")
          .find("span")
          .click(function () {
            $(".cookie-text").slideUp();
            $(".my_cookielayer_settings_section").find("a").html("&#65291;");

            if ($(this).siblings("p").hasClass("non-active-text")) {
              $(".my_cookielayer_settings_section")
                .find(".visible-text")
                .removeClass("visible-text")
                .addClass("non-active-text");
              $(this)
                .siblings("p")
                .addClass("visible-text")
                .removeClass("non-active-text")
                .slideDown();
              $(this).siblings("a").html("&#65293;");
            } else {
              $(this)
                .siblings("p")
                .removeClass("visible-text")
                .addClass("non-active-text")
                .slideUp();
              $(this).siblings("a").html("&#65291;");
            }
          });

        _this.update();

        $(window).bind("resize", function () {
          return _this.update();
        });

        return this.options.callback.init(this.options.init);
      }),
        (Plugin.prototype.showCookieBar = function () {
          setTimeout(function () {
            $(".my_cookiebar").slideDown();
          }, 500);
        }),
        (Plugin.prototype.hideCookieBar = function () {
          $(".my_cookiebar").slideUp();
          var _this = this;
          _this.setCookie("my_cookielayer_hide", "true", 200);
        }),
        (Plugin.prototype.showCookieLayer = function () {
          $(".my_cookielayer").fadeIn();
          $(".my_cookielayer_overlay").fadeIn();
        }),
        (Plugin.prototype.hideCookieLayer = function () {
          $(".my_cookielayer").fadeOut();
          $(".my_cookielayer_overlay").fadeOut();
        }),
        (Plugin.prototype.update = function () {
          var $element = $(this.element);
          var _this = this;

          if ($(window).width() < 600) {
            $(".my_cookielayer").css(this.options.css_mobile.modal);
            $(".my_cookielayer_settings_buttons, .my_cookielayer_buttons").css(
              this.options.css_mobile.buttons
            );
          }

          return this.options.callback.update(this.options.update);
        });

      Plugin.prototype.setCookie = function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      };

      Plugin.prototype.getCookie = function (cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(";");
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      };

      return ($.fn[pluginName] = function (options) {
        return this.each(function () {
          if (!$.data(this, "plugin_" + pluginName)) {
            return $.data(
              this,
              "plugin_" + pluginName,
              new Plugin(this, options)
            );
          }
        });
      });
    })(jQuery, window, document);
  }.call(this));
}
